import { Center, FormControl, FormErrorMessage, FormLabel, Stack, Text } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import { SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'wouter'
import { apiConfig } from '../../../common/apiConfig'
import { TextLink } from '../../../common/components/TextLink'
import { FormControlWrapper } from '../../../common/form/FormInput'
import { useSending } from '../../../common/form/useSending'
import { PasswordInput } from '../../../component/manager/common/atoms/passwordInput/PasswordInput'
import { ApplicantRegistrationsApi } from '../../../generated'
import { Button } from '../Button'
import { Title } from '../Title'

interface FormType {
  password: string
  password_repeat: string
}

export const RegistrationForm: FC<{ token: string; email: string }> = ({ token, email }) => {
  const { send, sending } = useSending()
  const [t] = useTranslation()

  const { control, handleSubmit, watch } = useForm<FormType>({
    mode: 'onBlur',
  })
  const [, setLocation] = useLocation()

  const registerPassword = useCallback(
    async (form) => {
      await send(async () => {
        const api = new ApplicantRegistrationsApi(apiConfig)
        await api.finish(token, { password: form.password })
        setLocation('/registrations-complete')
      })
    },
    [token, setLocation, send]
  )

  const onError: SubmitErrorHandler<FormType> = useCallback((error, event) => {
    console.info('submit error', error, event)
  }, [])

  const watchedPassword = watch('password')

  return (
    <div>
      <Title text={t('新規申請者登録')} />

      <div className={'mt24'}>
        <Stack>
          <FormControl as={'fieldset'} display={'flex'} flexDir={'row'} alignItems={`baseline`}>
            <FormLabel minW={'160px'} fontSize={`14px`} fontWeight={700}>
              {t('メールアドレス')}
            </FormLabel>
            <Stack>
              <div>{email}</div>
            </Stack>
          </FormControl>

          <FormControlWrapper<FormType>
            control={control}
            path={'password'}
            skipNormalize={true}
            options={{
              required: '必須入力です。',
              pattern: {
                value:
                  /^(?=.*[!-/:-@¥[-`{-~])(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!-/:-@¥[-`{-~]{12,100}$/,
                message: '半角英数字記号それぞれ1種類以上を含む12文字以上で入力してください。',
              },
            }}
          >
            {({ id, isInvalid, error, ...inputProps }) => (
              <FormControl
                id={id}
                isRequired={true}
                isInvalid={isInvalid ? true : undefined}
                as={'fieldset'}
                display={'flex'}
                flexDir={'row'}
                alignItems={`baseline`}
              >
                <FormLabel minW={'160px'} fontSize={`14px`} fontWeight={700}>
                  {t('パスワード')}
                </FormLabel>
                <Stack>
                  <PasswordInput size={'sm'} {...inputProps} w={'24rem'} {...inputProps} />
                  <Text fontSize="xs">
                    {t('半角英数字記号をそれぞれ1種類以上含み、12文字以上で入力してください。')}
                  </Text>
                  {error?.message && <FormErrorMessage>{t(error.message)}</FormErrorMessage>}
                </Stack>
              </FormControl>
            )}
          </FormControlWrapper>

          <FormControlWrapper<FormType>
            control={control}
            path={'password_repeat'}
            skipNormalize={true}
            options={{
              required: '必須入力です。',
              validate: (value) => value === watchedPassword || 'パスワードが一致していません。',
            }}
          >
            {({ id, isInvalid, error, ...inputProps }) => (
              <FormControl
                id={id}
                isRequired={true}
                isInvalid={isInvalid ? true : undefined}
                as={'fieldset'}
                display={'flex'}
                flexDir={'row'}
                alignItems={`baseline`}
              >
                <FormLabel minW={'160px'} fontSize={`14px`} fontWeight={700}>
                  {t('パスワード確認')}
                </FormLabel>
                <Stack>
                  <PasswordInput size={'sm'} {...inputProps} w={'24rem'} {...inputProps} />
                  {error?.message && <FormErrorMessage>{t(error.message)}</FormErrorMessage>}
                </Stack>
              </FormControl>
            )}
          </FormControlWrapper>
        </Stack>

        <Center className={'mt16'}>
          <Button
            disabled={sending}
            role={'primary'}
            size={'md'}
            text={t('登録')}
            w={'200px'}
            onClick={handleSubmit(registerPassword, onError)}
          />
        </Center>

        <Center className={'mt8'}>
          <TextLink text={t('キャンセル')} to={'/login'} />
        </Center>
      </div>
    </div>
  )
}
