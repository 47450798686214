import { Icon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { DevTool } from '@hookform/devtools'
import React, { FC, memo, useCallback, useState, VFC } from 'react'
import {
  Control,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormRegisterReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GoArrowRight } from 'react-icons/all'
import { Link as WouterLink } from 'wouter'
import { TextLink } from '../../../common/components/TextLink'
import { FormControlWrapper } from '../../../common/form/FormInput'
import { FormProps } from '../../../common/form/useFormProps'
import { useLang } from '../../../common/i18n/useLang'
import { PasswordInput } from '../../../component/manager/common/atoms/passwordInput/PasswordInput'
import { SignInWithCodeRequest } from '../../../generated'
import { signIn, verification } from '../../hooks/useApplicant'
import { Button } from '../Button'

type LoginInput = {
  email: string
  password: string
  verificationCode: string
}

const _LoginButtons: VFC = () => {
  const [t] = useTranslation()
  return (
    <Stack>
      <Center>
        <Button
          role={'primary'}
          size={'md'}
          type={'submit'}
          text={t('ワンタイムパスワード発行')}
          w={'240px'}
        />
      </Center>
      {/*<Center>*/}
      {/*  <TextLink text={t('パスワードをお忘れの方はこちら')} to={'/password-reset-request'} />*/}
      {/*</Center>*/}
    </Stack>
  )
}
const LoginButtons = memo(_LoginButtons)

type RowProps = {
  label: string
  children: (props: UseFormRegisterReturn) => JSX.Element
  suffixIcon?: JSX.Element
} & FormProps<LoginInput>

export const Row: FC<RowProps> = ({ label, children, suffixIcon, ...props }) => {
  const { t, language } = useLang()
  return (
    <FormControlWrapper<LoginInput> {...props}>
      {({ id, isInvalid, error, ...inputProps }) => (
        <FormControl
          id={id}
          isRequired={false}
          isInvalid={isInvalid ? true : undefined}
          as={'fieldset'}
          flexDir={'row'}
          alignItems={'baseline'}
          display={'flex'}
          justifyContent={'center'}
        >
          <HStack alignItems={'center'} spacing={2} mr={8}>
            <FormLabel
              minW={language === 'en' ? '300px' : '160px'}
              mb={0}
              fontSize={`14px`}
              fontWeight={700}
            >
              {label}
            </FormLabel>
            {suffixIcon}
          </HStack>
          <Stack>
            {children({ ...inputProps })}
            {error?.message && (
              <FormErrorMessage w={'24rem'} ml={4} mt={0}>
                {t(error.message)}
              </FormErrorMessage>
            )}
          </Stack>
        </FormControl>
      )}
    </FormControlWrapper>
  )
}

export const LoginForm: FC = () => {
  const { t, language } = useLang()
  const [isIssue, setIsIssue] = useState(false)
  const [sessions, setSessions] = useState<{ email: string; password: string }>()
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()

  const methods = useForm<LoginInput>({
    mode: 'onBlur',
  })
  const { control, handleSubmit, setError } = methods
  const verificationMethods = useForm<SignInWithCodeRequest>({ mode: 'onBlur' })

  const login: SubmitHandler<LoginInput> = useCallback(
    async (input) => {
      try {
        await signIn(input.email, input.password)
        setIsIssue(true)
        setSessions({ email: input.email, password: input.password })
        verificationMethods.setValue('email', input.email)
        verificationMethods.setValue('password', input.password)
        toast({
          title: (
            <Box>
              {language === 'en' ? (
                <Text>
                  Please enter the one-time password
                  <br />
                  that was sent to your registered e-mail address to log-in.
                </Text>
              ) : (
                <Text>
                  ワンタイムパスワードを登録済のメールアドレス宛に送信しました。
                  <br />
                  ワンタイムパスワードを入力の上、ログインを完了してください。
                </Text>
              )}
            </Box>
          ),
          status: 'info',
          duration: 6000,
          isClosable: true,
        })
      } catch (error) {
        switch (error.status) {
          case 404:
            setError('email', {
              type: 'manual',
              message: 'アカウントが見つかりません。',
            })
            break
          case 403:
            setError('password', {
              type: 'manual',
              message: 'パスワードが正しくありません。',
            })
            break
        }
      }
    },
    [language, setError, toast, verificationMethods]
  )

  const reissue = useCallback(async () => {
    try {
      if (sessions) {
        await signIn(sessions.email, sessions.password)
        toast({
          title: (
            <Box>
              {language === 'en' ? (
                <Text>
                  Please enter the one-time password
                  <br />
                  that was sent to your registered e-mail address to log-in.
                </Text>
              ) : (
                <Text>
                  ワンタイムパスワードを登録済のメールアドレス宛に再送信しました。
                  <br />
                  ワンタイムパスワードを入力の上、ログインを完了してください。
                </Text>
              )}
            </Box>
          ),
          status: 'info',
          duration: 6000,
          isClosable: true,
        })
      }
    } catch (error) {
      switch (error.status) {
        case 404:
          setError('email', {
            type: 'manual',
            message: 'アカウントが見つかりません。',
          })
          break
        case 403:
          setError('password', {
            type: 'manual',
            message: 'パスワードが正しくありません。',
          })
          break
      }
    }
  }, [language, sessions, setError, toast])

  const onVerification: SubmitHandler<SignInWithCodeRequest> = useCallback(
    async (input) => {
      try {
        await verification(input.email, input.password, input.verificationCode)
      } catch (error) {
        switch (error.status) {
          case 403:
            verificationMethods.setError('verificationCode', {
              type: 'manual',
              message: t('ワンタイムパスワードが正しくありません。'),
            })
            break
        }
      }
    },
    [t, verificationMethods]
  )

  const onError: SubmitErrorHandler<LoginInput> = useCallback((error, event) => {
    console.info('submit error', error, event)
  }, [])

  const onErrorForVerification: SubmitErrorHandler<SignInWithCodeRequest> = useCallback(
    (error, event) => {
      console.info('verification error', error, event)
    },
    []
  )

  return (
    <Stack spacing={0} w={'70%'}>
      {/*<Title text={t('ログイン')} />*/}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(login, onError)} noValidate={true}>
          {import.meta.env.DEV && (
            <DevTool control={control as Control<any>} placement={'top-right'} />
          )}
          <Stack spacing={4} mt={10}>
            <Box>
              {/*<FormLabel minW={'200px'} fontSize={`14px`} fontWeight={700}>*/}
              {/*  ① {t('ワンタイムパスワード作成')}*/}
              {/*</FormLabel>*/}
              <Box textAlign={'center'}>
                {t(
                  'ログインするには、登録されたメールアドレスとパスワードを入力し、ワンタイムパスワードを発行してください。'
                )}
              </Box>
            </Box>
            <Row
              control={control}
              path={'email'}
              label={`1. ${t('登録済メールアドレス')}`}
              suffixIcon={
                <Image
                  src={`${import.meta.env.BASE_URL}login.svg`}
                  alt={''}
                  h={'24px'}
                  display={'inline-block'}
                />
              }
              options={{
                required:
                  '必須入力です。メールアドレス・パスワードの登録がお済みでない方は、はじめにページ下部の「新規申請者登録」より申請者登録を完了してください。',
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  message: 'メールアドレスを入力してください。',
                },
              }}
            >
              {(props) => (
                <Input
                  {...props}
                  size={'sm'}
                  type="email"
                  w={'24rem'}
                  autoComplete={'email'}
                  maxLength={200}
                  isDisabled={isIssue}
                />
              )}
            </Row>

            <Row
              control={control}
              path={'password'}
              label={`2. ${t('登録済パスワード')}`}
              suffixIcon={
                <Image src={`${import.meta.env.BASE_URL}login.svg`} alt={''} h={'24px'} />
              }
              skipNormalize={true}
              options={{
                required:
                  '必須入力です。メールアドレス・パスワードの登録がお済みでない方は、はじめにページ下部の「新規申請者登録」より申請者登録を完了してください。',
                pattern: {
                  value:
                    /^(?=.*[!-/:-@¥[-`{-~])(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!-/:-@¥[-`{-~]{12,100}$/,
                  message: 'パスワードは記号を含む半角英数12文字以上100文字以内です。',
                },
              }}
            >
              {(props) => (
                <>
                  <PasswordInput
                    size={'sm'}
                    {...props}
                    w={'24rem'}
                    autoComplete={'current-password'}
                    isDisabled={isIssue}
                  />
                  <Box>
                    <TextLink
                      text={t('パスワードをお忘れの方はこちら')}
                      to={'/password-reset-request'}
                    />
                  </Box>
                </>
              )}
            </Row>
          </Stack>
        </form>
      </FormProvider>

      <FormProvider {...verificationMethods}>
        <form
          onSubmit={verificationMethods.handleSubmit(onVerification, onErrorForVerification)}
          noValidate={true}
        >
          <Stack spacing={4} mt={4}>
            {/*<Box>*/}
            {/*  <FormLabel minW={'200px'} fontSize={`14px`} fontWeight={700}>*/}
            {/*    ② {t('ワンタイムパスワード入力')}*/}
            {/*  </FormLabel>*/}
            {/*  <Box>*/}
            {/*    {t('ご自身のメールアドレスに届いたワンタイムパスワードを入力してください。')}*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <Row
              control={verificationMethods.control}
              path={'verificationCode'}
              label={`3. ${t('ワンタイムパスワード')}`}
              suffixIcon={<Image src={`${import.meta.env.BASE_URL}otp.svg`} alt={''} h={'24px'} />}
              options={{
                required: '必須入力です。',
              }}
            >
              {(props) => (
                <Input {...props} size={'sm'} w={'24rem'} maxLength={200} disabled={!isIssue} />
              )}
            </Row>

            <Stack>
              <Center>
                <Button
                  role={'primary'}
                  size={'md'}
                  type={'submit'}
                  text={t('ログイン')}
                  w={'240px'}
                  disabled={!isIssue}
                />
              </Center>
              {/*<Center>*/}
              {/*  <TextLink*/}
              {/*    text={t('パスワードをお忘れの方はこちら')}*/}
              {/*    to={'/password-reset-request'}*/}
              {/*  />*/}
              {/*</Center>*/}
            </Stack>
          </Stack>
        </form>
      </FormProvider>

      {/*<Box color={'#E53E3E'}>*/}
      {/*  {language === 'en' ? (*/}
      {/*    <>*/}
      {/*      If you have not yet registered your email address and password,*/}
      {/*      <br />*/}
      {/*      please first complete your registration by clicking the “Registration” button on*/}
      {/*      the left side.*/}
      {/*    </>*/}
      {/*  ) : (*/}
      {/*    <>*/}
      {/*      メールアドレス・パスワードの登録がお済みでない方は、*/}
      {/*      <br />*/}
      {/*      はじめに左側の「新規申請者登録」より申請者登録を完了してください。*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</Box>*/}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(login, onError)} noValidate={true}>
          <Stack border={`thick double #005E3C`} py={8} mt={8}>
            <HStack spacing={10} justifyContent={'center'}>
              <HStack>
                <Image src={`${import.meta.env.BASE_URL}otp.svg`} alt={''} h={'24px'} />
                <Text
                  w={language === 'en' ? '300px' : '260px'}
                  textDecoration={'underline'}
                  fontWeight={'bold'}
                >
                  {t('ワンタイムパスワードの発行')}
                </Text>
                <Icon as={GoArrowRight} h={'40px'} color={'gray.500'} />
              </HStack>
              {isIssue ? (
                <Stack>
                  <Center>
                    <Button
                      role={'primary'}
                      size={'md'}
                      text={t('ワンタイムパスワード発行')}
                      w={'240px'}
                      onClick={reissue}
                    />
                  </Center>
                </Stack>
              ) : (
                <LoginButtons />
              )}
            </HStack>
            <HStack spacing={10} justifyContent={'center'}>
              <HStack>
                <Image
                  src={`${import.meta.env.BASE_URL}login.svg`}
                  alt={''}
                  h={'24px'}
                  display={'inline-block'}
                />
                <Text
                  w={language === 'en' ? '300px' : '260px'}
                  textDecoration={'underline'}
                  fontWeight={'bold'}
                >
                  {t('メールアドレス・パスワードの新規登録')}
                </Text>
                <Icon as={GoArrowRight} h={'40px'} color={'gray.500'} />
              </HStack>
              <WouterLink to={'registrations-request'}>
                <Button role={'warning'} size={'md'} text={t('新規申請者登録')} w={'240px'} />
              </WouterLink>
            </HStack>
          </Stack>
        </form>
      </FormProvider>
      {/*<SignUpLink />*/}
    </Stack>
  )
}
