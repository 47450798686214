import {Box, Center, ChakraProvider, Link, Stack, Table, Td, Text, Th, Tr,} from '@chakra-ui/react'
import React, {lazy, Suspense, useEffect, VFC} from 'react'
import {QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {useTitle} from "react-use";
import {Route, Switch, useLocation} from 'wouter'
import {DefaultLoading} from '../common/components/DefaultLoader'
import {createQueryClient} from '../common/createQueryClient'
import {useLang} from '../common/i18n/useLang'
import {useOptionStores} from '../common/store/useOptionStores'
import '../index.css'
import theme from '../varables/common/theme'
import {Button} from './components/Button'
import {EmailChangeRequestForm} from './components/emailChangeRequest/EmailChangeRequestForm'
import {Header} from './components/Header'
import {onSignOut, useApplicant} from './hooks/useApplicant'
import EmailChangeComplete from './pages/EmailChangeComplete'
import EmailChangeRequestComplete from './pages/EmailChangeRequestComplete'
import Login from './pages/Login'
import {NotFound} from './pages/NotFound'
import PasswordChange from './pages/PasswordChange'
import PasswordChangeComplete from './pages/PasswordChangeComplete'
import PasswordReset from './pages/PasswordReset'
import PasswordResetComplete from './pages/PasswordResetComplete'
import PasswordResetRequest from './pages/PasswordResetRequest'
import PasswordResetRequestComplete from './pages/PasswordResetRequestComplete'
import Privacy from './pages/Privacy'
import Registration from './pages/Registration'
import RegistrationComplete from './pages/RegistrationComplete'
import RegistrationRequest from './pages/RegistrationRequest'
import RegistrationRequestComplete from './pages/RegistrationRequestComplete'

// lazy load は大きなコンポーネントだけにする。build 時に細かい chunk ができすぎる。
const Application = lazy(() => import('./pages/Application'))
const skipLogin =
  /^\/($|registrations|password-reset|privacy|change-email-requests|password-change-complete|login|apps)/

const Index: VFC = () => {
  const [location, setLocation] = useLocation()
  const { loadOptions } = useOptionStores()
  const { state } = useApplicant()

  useEffect(() => {
    switch (state) {
      case 'signedIn':
        if (location === '/login') {
          setLocation('/applications')
        }
        break
      case 'signedOut':
      case 'terminated':
        if (!skipLogin.test(location)) {
          console.info('not skip!', skipLogin.test(location), location)
          setLocation('/')
        }
        break
    }
  }, [location, setLocation, state])

  useEffect(() => {
    loadOptions()
  }, [loadOptions])

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Suspense fallback={<DefaultLoading />}>
        {state === 'loading' ? (
          <DefaultLoading />
        ) : (
          <Switch>
            <Route path="/applications" component={Application} />
            <Route path="/change-email-requests/:id" component={EmailChangeComplete} />
            <Route path="/email-change-request-complete" component={EmailChangeRequestComplete} />
            <Route path="/email-change-request" component={EmailChangeRequestForm} />
            <Route path="/password-change" component={PasswordChange} />
            <Route path="/password-change-complete" component={PasswordChangeComplete} />
            <Route path="/password-reset-complete" component={PasswordResetComplete} />
            <Route path="/password-reset/:id" component={PasswordReset} />
            <Route
              path="/password-reset-request-complete"
              component={PasswordResetRequestComplete}
            />
            <Route path="/password-reset-request" component={PasswordResetRequest} />
            <Route path="/registrations-complete" component={RegistrationComplete} />
            <Route path="/registrations/:id" component={Registration} />
            <Route path="/registrations-request-complete" component={RegistrationRequestComplete} />
            <Route path="/registrations-request" component={RegistrationRequest} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/" component={TopPage} />
            <Route path="/login" component={Login} />
            <Route path="/:rest*" component={NotFound} />
          </Switch>
        )}
      </Suspense>
    </ChakraProvider>
  )
}

const TopPage: VFC = () => {
  const { t, language } = useLang()
  useTitle(`HU-IAAS`)
  return (
    <Center>
      <Stack spacing={10} mt={10} alignItems={'center'}>
        <Stack width={'720px'}>
          <Box borderBottom={`1px solid #005E3C`} fontSize={language === 'en' ? '16px' : '18px'}>
            ■ {t('広島大学IAASについて')}
          </Box>
          <Table>
            <Tr h={language === 'en' ? '73px' : 'none'}>
              <Th bgColor={'#BEE3F8'} p={2} textAlign={'center'}>
                {t('できること')}
              </Th>
              <Td pl={8}>
                <ul>
                  <li>{t('希望指導教員とのコンタクト開始支援')}</li>
                </ul>
              </Td>
            </Tr>
            <Tr>
              <Th bgColor={'#E2E8F0'} p={2} textAlign={'center'}>
                {t('できないこと')}
              </Th>
              <Td pl={8}>
                <ul>
                  <li>{t('大学院入試／外国人研究生の出願手続')}</li>
                </ul>
              </Td>
            </Tr>
          </Table>
          <Box>
            <Text color={'#E53E3E'} fontWeight={'bold'}>
              {t(
                '大学院入試および外国人研究生の出願には、別途希望する研究科での手続きが必要です。'
              )}
            </Text>
            <Text>
              {t(
                '出願方法や出願期間等の詳細については、各研究科のウェブサイトや学生募集要項で確認してください。'
              )}
            </Text>
            <Link
              href={
                language === 'ja'
                  ? 'https://www.hiroshima-u.ac.jp/international/contact_information'
                  : 'https://www.hiroshima-u.ac.jp/en/international/contact_information'
              }
              target={'_blank'}
              color="#0058C3"
            >
              {t('各研究科窓口一覧')}
            </Link>
            <br />
            <Link
              href={
                language === 'ja'
                  ? 'https://www.hiroshima-u.ac.jp/international/iao'
                  : 'https://www.hiroshima-u.ac.jp/en/international/iao-e'
              }
              target={'_blank'}
              color="#0058C3"
            >
              {t('広島大学IAASの詳細について')}
            </Link>
          </Box>
        </Stack>
        {/*<Box border={`4px solid #005E3C`} width={'480px'} bgColor={'#E8F3F5'}>*/}
        {/*  <Box bgColor={'#005E3C'} width={'50%'} margin={'auto'}>*/}
        {/*    <Text color={'#FFF'} fontFamily={'Lora, serif'} fontSize={'12px'} textAlign={'center'}>*/}
        {/*      HIROSHIMA UNIVERSITY*/}
        {/*    </Text>*/}
        {/*  </Box>*/}
        {/*  <Box padding={2}>*/}
        {/*    <Text*/}
        {/*      fontFamily={'Lora, serif'}*/}
        {/*      color={'#1C4D5D'}*/}
        {/*      fontSize={'24px'}*/}
        {/*      fontWeight={'400'}*/}
        {/*      textAlign={'center'}*/}
        {/*    >*/}
        {/*      International Admissions Office System*/}
        {/*    </Text>*/}
        {/*  </Box>*/}
        {/*</Box>*/}
        <Link href={'/login'} _hover={{ textDecoration: 'none' }}>
          <Button role={'primary'} text={t('入学希望者はこちら')} size={'lg'} extendSize={true} />
        </Link>
        <Link href={'/professor'} _hover={{ textDecoration: 'none' }}>
          <Button role={'primary'} text={t('教員はこちら')} size={'lg'} extendSize={true} />
        </Link>
        <Link href={'/office'} _hover={{ textDecoration: 'none' }}>
          <Button role={'primary'} text={t('支援室はこちら')} size={'lg'} extendSize={true} />
        </Link>
      </Stack>
    </Center>
  )
}

// ユーザーごとにキャッシュなど別管理したいため
const queryClient = createQueryClient(onSignOut)
export const ApplicantIndex: VFC = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Index />
  </QueryClientProvider>
)
