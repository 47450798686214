import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Center,
  HStack,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import React, { VFC } from 'react'
import { useTitle } from 'react-use'
import { TextLink } from '../../common/components/TextLink'
import { useLang } from '../../common/i18n/useLang'
import { Button } from '../components/Button'
import { Footer } from '../components/Footer'
import { LoginForm } from '../components/login/LoginForm'
import { useApplicantSession } from '../hooks/useApplicant'

const Login: VFC = () => {
  const { t, language } = useLang()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useTitle(`HU-IAAS ${t('ログイン')}`)
  const state = useApplicantSession()
  return (
    <VStack position={'relative'} w={'100%'}>
      {state === 'terminated' && (
        <Alert status={'error'} w={'800px'} m={'2rem auto 0'} p={6}>
          <AlertIcon />
          {t('セッションが切断されました')}
        </Alert>
      )}
      <Box
        w={'1200px'}
        minH={'calc(100vh - 44px - 100px - 52px)'}
        marginX={'auto'}
        pt={'24px'}
        px={'16px'}
        pb={'48px'}
      >
        <HStack mb={4} justifyContent={'space-between'} alignItems={'center'}>
          <Badge variant={'outline'} colorScheme={'red'} fontSize={'xl'}>
            {t('学生申請者用')}
          </Badge>
          <HStack>
            <Link href={'/professor'} _hover={{ textDecoration: 'none' }}>
              <Button role={'secondary'} text={t('教員用ページ')} size={'xs'} />
            </Link>
            <Link href={'/office'} _hover={{ textDecoration: 'none' }}>
              <Button role={'secondary'} text={t('支援室用ページ')} size={'xs'} />
            </Link>
          </HStack>
        </HStack>
        <HStack alignItems={'start'} justifyContent={'center'}>
          {/*<SignUpLink />*/}
          <LoginForm />
        </HStack>

        <Center>
          <Box mt={8}>
            {language === 'en' ? (
              <p>
                Please access the IAO system using{' '}
                <Link color={'#0058C3'} onClick={onOpen}>
                  {'the supported browsers'}
                </Link>
                .
              </p>
            ) : (
              <p>
                ご利用環境については
                <Link color={'#0058C3'} onClick={onOpen}>
                  {'推奨ブラウザ'}
                </Link>
                を御覧ください。
              </p>
            )}
            <p>
              {t('個人情報の取り扱いに関しては「')}
              <TextLink text={t('個人情報保護方針')} to={'/privacy'} />
              {t('」をご覧下さい。')}
            </p>
          </Box>
        </Center>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('ご利用環境について')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {language === 'en' ? (
                <p>
                  Please use <b>the latest version</b> of the following browsers.
                </p>
              ) : (
                <p>
                  以下の推奨ブラウザの<b>最新版</b>をご利用ください。
                </p>
              )}
              <UnorderedList p={2}>
                <ListItem>
                  <Link
                    color={'#0058C3'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.google.com/chrome/'}
                  >
                    Google Chrome
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color={'#0058C3'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.microsoft.com/edge'}
                  >
                    Microsoft Edge
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color={'#0058C3'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.mozilla.org/firefox/browsers/'}
                  >
                    Firefox
                  </Link>
                </ListItem>
                <ListItem>
                  <Link
                    color={'#0058C3'}
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={'https://www.apple.com/safari/'}
                  >
                    Safari
                  </Link>{' '}
                  (macOS only)
                </ListItem>
              </UnorderedList>
            </ModalBody>
            <Center mb={4}>
              <Button
                onClick={onClose}
                role={'secondary'}
                size={'sm'}
                text={t('閉じる') as string}
              />
            </Center>
          </ModalContent>
        </Modal>
      </Box>
      <Footer />
    </VStack>
  )
}

export default Login
