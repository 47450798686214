import { useQuery } from 'react-query'
import { apiConfig } from '../../common/apiConfig'
import { createSessionState } from '../../common/session/state'
import { ApplicantSessionsApi, SignInWithCodeRequest } from '../../generated'

const api = new ApplicantSessionsApi(apiConfig)
const current = api.current.bind(api)

async function _signIn(email: string, password: string) {
  return api.signIn({ email, password })
}
const _signOut = api.signOut.bind(api)

async function _verification(req: SignInWithCodeRequest) {
  return api.signInWithVerificationCode(req)
}

export const {
  onSignOut,
  signIn,
  signOut,
  verification,
  onError,
  useSession: useApplicantSession,
  send,
} = createSessionState(_signIn, _signOut, _verification)

export function useApplicant() {
  const { data } = useQuery('useApplicant', current, {
    onSuccess: () => send('onSuccess'),
    onError,
  })
  const state = useApplicantSession()
  return {
    state,
    email: data?.email,
  }
}
