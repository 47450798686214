import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Center, Link, ListItem, Stack, UnorderedList } from '@chakra-ui/react'
import React, { VFC } from 'react'
import { useTitle } from 'react-use'
import { Link as WouterLink } from 'wouter'
import { Container } from '../../common/components/Container'
import { isJa, useLang } from '../../common/i18n/useLang'
import { Title } from '../components/Title'

const jaText = (
  <div>
    広島大学International Admission Office
    System（IAAS）は、個人情報保護の重要性を認識し、IAAS申請システムにおいて、その扱いに当たっては、法令を遵守し、
    <Link href="https://www.hiroshima-u.ac.jp/koho_press/policy/privacy" isExternal color="#0058C3">
      広島大学における個人情報保護に関する規定
      <ExternalLinkIcon mx="2px" />
    </Link>
    に基づき個人情報の保護に努めるとともに以下の方針に基づき個人情報の保護に努めます。
  </div>
)

const enText = (
  <div>
    In recognition of the growing importance of privacy protection, Hiroshima University
    International Admissions Assistant System (IAAS) strives to comply with all applicable laws and to
    protect personal information on the IAO application system in accordance with its
    <Link
      href="https://www.hiroshima-u.ac.jp/en/koho_press/policy/privacy"
      isExternal
      color="#0058C3"
      pl={1}
    >
      Regulations Concerning the Handling of Personal Information
      <ExternalLinkIcon mx="2px" />
    </Link>
    and the privacy policy described below.
  </div>
)

const Privacy: VFC = () => {
  const { t } = useLang()
  useTitle(t('IAAS 個人情報の取り扱いについて'))
  return (
    <Container innerWidth={'800px'}>
      <Stack spacing={8}>
        <Stack spacing={4}>
          <Title text={t('個人情報の取り扱いについて')} />
          {isJa() ? jaText : enText}
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の利用について')} />
          <UnorderedList pl={'1.5em'}>
            <ListItem>
              {t(
                '広島大学IAASは、法令に基づく場合を除き、個人情報を、取得の際に示した利用目的以外の目的で利用、又は第三者に提供しません。'
              )}
            </ListItem>
            <ListItem>
              {t(
                '広島大学IAASは、法令に定める場合を除き、個人情報を、事前に本人の同意を得ることなく、第三者に提供しません。'
              )}
            </ListItem>
            <ListItem>
              {t(
                '広島大学IAASは、法令に基づいて、個人情報を第三者に提供する、又は個人情報の取扱いに係る業務を第三者に委託する場合には、当該第三者につき、当該個人情報の安全確保、管理の能力等について厳正な調査を行い、個人情報を取り扱わせるに当たっては適正な監督を行います。'
              )}
            </ListItem>
          </UnorderedList>
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の管理について')} />
          <UnorderedList pl={'1.5em'}>
            <ListItem>
              {t(
                '広島大学IAASは、保有する個人情報について正確性を保つとともに、その紛失、破壊、改ざん及び漏えい等を防止するため、不正アクセス、コンピュータウイルス等に対する適正な情報セキュリティ対策を講じます。'
              )}
            </ListItem>
          </UnorderedList>
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('個人情報の保管期間')} />
          <UnorderedList pl={'1.5em'}>
            <ListItem>
              {t(
                '取得した個人情報は、利用目的を達成するまでの間、大学の規則に基づいて必要とされる期間保管します。'
              )}
            </ListItem>
          </UnorderedList>
        </Stack>
        <Stack spacing={0}>
          <Title level={`h2`} text={t('Cookieに関して')} />
          <UnorderedList pl={'1.5em'}>
            <ListItem>
              {t(
                'Cookieとは、ユーザーがウェブサイトにアクセスしたときにブラウザに送信される小さなテキストデータです。広島大学IAASは、セキュリティやログイン管理やなどの基本機能およびウェブサイトの利便性等向上のための解析目的でCookieを使用することがあります。'
              )}
            </ListItem>
          </UnorderedList>
        </Stack>

        <Center>
          <Link as={WouterLink} to="/login" isExternal color="#0058C3">
            {t('トップページへ戻る')}
          </Link>
        </Center>
      </Stack>
    </Container>
  )
}

export default Privacy
