import { Center } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTitle } from 'react-use'
import { Container } from '../../common/components/Container'
import { TextLink } from '../../common/components/TextLink'
import { Title } from '../components/Title'

const EmailChangeRequestComplete: FC = () => {
  const [t] = useTranslation()
  useTitle(t('IAOS メールアドレス変更'))

  return (
    <Container innerWidth={'800px'}>
      <Title text={t('メールアドレス変更')} />
      <div className={'mt24'}>
        {t('入力されたメールアドレス宛にメールを送信しました。')}
        <br />
        {t('メール本文に記載されているURLにアクセスしてメールアドレス登録を行ってください。')}
      </div>
      <div className={'mt24'}>
        <Center className={'mt8'}>
          <TextLink text={t('申請画面へ戻る')} to={'/applications'} />
        </Center>
      </div>
    </Container>
  )
}

export default EmailChangeRequestComplete
