import { useCallback } from 'react'
import { loadCountries } from './CountriesStore'
import { loadCourses } from './CoursesStore'
import { loadEducationalSystems } from './EducationSystemsStore'
import { loadEducationTypes } from './EducationTypesStore'
import {loadEnrollmentTerm} from "./EnrollmentTermStore";
import { loadPartnerSchoolTypes } from './PartnerSchoolTypeStore'
import { loadPrograms } from './ProgramsStore'
import { loadScholarshipFinancialSupports } from './ScholarshipsFinancialSupporsStore'
import { loadScholarshipStatuses } from './ScholarshipStatusStore'
import { loadScholarshipTypes } from './ScholarshipTypesStore'
import { loadSupplementalMaterialTypes } from './SupplementalMaterialTypesStore'

export function useOptionStores() {
  const loadOptions = useCallback(async () => {
    loadEducationTypes().then()
    loadEducationalSystems().then()
    loadCourses().then()
    loadCountries().then()
    loadPartnerSchoolTypes().then()
    loadPrograms().then()
    loadScholarshipFinancialSupports().then()
    loadScholarshipStatuses().then()
    loadScholarshipTypes().then()
    loadSupplementalMaterialTypes().then()
    loadEnrollmentTerm().then()
  }, [])

  return { loadOptions }
}
