import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const enrollmentTermStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadEnrollmentTerm() {
  await api.getAnticipatedEnrollmentTerms().then((result) => {
    enrollmentTermStore.items = result
  })
}

export function getEnrollmentTermName(id?: number, defaultValue?: string) {
  // その他が0なのでundefinedで判断
  if (id === undefined) return ''
  return getName(enrollmentTermStore.items, id, defaultValue)
}

// 研究生 と短期留学(大学・大学院)で選択肢が異なる。
// Hackyだがハードコーディングでお茶を濁す。
export function getEnrollmentTermOptions(program: Option | undefined, terms: { items: Option[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  return terms.items
    .filter((e) => {
      if (program?.id == 1 && [1,2].includes(e.id)) return true;
      if ((program?.id == 8 || program?.id == 9) && [3,4,5,99].includes(e.id)) return true;
      return false;
    })
    .map((e) => ({
      ...e,
      label: e[key],
    }))
}
