/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignInWithCodeRequest
 */
export interface SignInWithCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof SignInWithCodeRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignInWithCodeRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignInWithCodeRequest
     */
    verificationCode: string;
}

export function SignInWithCodeRequestFromJSON(json: any): SignInWithCodeRequest {
    return SignInWithCodeRequestFromJSONTyped(json, false);
}

export function SignInWithCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignInWithCodeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'verificationCode': json['verificationCode'],
    };
}

export function SignInWithCodeRequestToJSON(value?: SignInWithCodeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'verificationCode': value.verificationCode,
    };
}


