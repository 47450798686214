import React, { useEffect, useState, VFC } from 'react'
import { useTitle } from 'react-use'
import { useLocation, useRoute } from 'wouter'
import { apiConfig } from '../../common/apiConfig'
import { useLang } from '../../common/i18n/useLang'
import { ApplicantRegistrationsApi } from '../../generated'
import { ContainerWithAnnounce } from '../components/ContainerWithAnnounce'
import { RegistrationForm } from '../components/registration/RegistrationForm'

const Registration: VFC = () => {
  const [location, setLocation] = useLocation()
  const [, params] = useRoute<{ id: string }>('/registrations/:id')
  const token = params?.id
  const [email, setEmail] = useState('')
  const { t } = useLang()
  useTitle(t('IAOS 新規申請者登録'))

  useEffect(() => {
    token
      ? new ApplicantRegistrationsApi(apiConfig)
          .getRegistration(token)
          .then((res) => {
            setEmail(res.email)
          })
          .catch((error) => {
            switch (error.status) {
              case 404:
                alert(t('URLが無効です。'))
                setLocation('/')
                console.log('error', error)
                break
              default:
                console.log('error', error)
            }
          })
      : null
  }, [setLocation, setEmail, token, t])

  // unmount される前に呼ばれるっぽい
  useEffect(() => {
    if (!token && location.startsWith('/registrations/')) {
      setLocation('/')
    }
  }, [token, location, setLocation])

  if (!token) return null

  return (
    <ContainerWithAnnounce>
      <RegistrationForm token={token} email={email} />
    </ContainerWithAnnounce>
  )
}

export default Registration
