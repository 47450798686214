export enum ColorEnums {
  Primary = '#005A86',
  Destructive = '#B51D1D',
  Secondary = '#005A86',
  Warning = '#ea9327',
  Disable = '#8D949A',
}

export enum HoverColorEnums {
  Primary = '#1B8CBC',
  Destructive = '#e43838',
  Secondary = '#1B8CBC',
  Warning = '#eca042',
  Disable = '#8D949A',
}
