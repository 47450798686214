import React, { useEffect, useState, VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTitle } from 'react-use'
import { useLocation, useRoute } from 'wouter'
import { apiConfig } from '../../common/apiConfig'
import { ApplicantPasswordsApi } from '../../generated'
import { ContainerWithAnnounce } from '../components/ContainerWithAnnounce'
import { PasswordResetForm } from '../components/passwordReset/PasswordResetForm'

const PasswordReset: VFC = () => {
  const [, setLocation] = useLocation()
  const [t] = useTranslation()
  useTitle(t('IAOS パスワードリセット'))
  const [, params] = useRoute<{ id: string }>('/password-reset/:id')
  const token = params?.id
  const [email, setEmail] = useState('')

  useEffect(() => {
    token
      ? new ApplicantPasswordsApi(apiConfig)
          .getResetRequest(token)
          .then((res) => {
            setEmail(res.email)
          })
          .catch((error) => {
            switch (error.status) {
              case 404:
                alert(t('URLが無効です。'))
                setLocation('/')
                console.log('error', error)
                break
              default:
                console.log('error', error)
            }
          })
      : null
  }, [setLocation, setEmail, token, t])

  if (!token) {
    setLocation('/')
    return null
  }

  return (
    <ContainerWithAnnounce>
      <PasswordResetForm token={token} email={email} />
    </ContainerWithAnnounce>
  )
}

export default PasswordReset
